import { useAuth0 } from '@auth0/auth0-react';
import { getKpiBookingAndAmountSummaries, getKpiBookingAndAmountSummariesByMonth, getKpiSummary } from 'api/kpi';
import LoadingSkeleton from 'components/loadingSkeleton';
import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip, CartesianGrid, Bar, Label, LineChart } from 'recharts';

const StartPage = () => {

    const [bookingsAndAmounts, setBookingsAndAmounts] = useState([]);
    const [filteredBookingsAndAmounts, setFilteredBookingsAndAmounts] = useState();
    const [bookingsAndAmountsByMonth, setBookingsAndAmountsByMonth] = useState([]);
    const [summary, setSummary] = useState();
    const [bookingsAndTurnOverStartDate, setBookingsAndTurnOverStartDate] = useState('2022-01-01');
    const [bookingsAndTurnOverEndDate, setBookingsAndTurnOverEndDate] = useState(new Date().toISOString().substring(0, 10));

    /* Get token from auth0 */
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const load = async () => {
            const token = await getAccessTokenSilently();
            console.log(token);
            const kpiBookingAndAmountsSummary = await getKpiBookingAndAmountSummaries(token);
            const kpiBookingAndAmountsSummaryByMonth = await getKpiBookingAndAmountSummariesByMonth(token);
            const kpiSummary = await getKpiSummary(token);
            setBookingsAndAmounts(kpiBookingAndAmountsSummary);
            setFilteredBookingsAndAmounts(kpiBookingAndAmountsSummary);
            console.log(kpiBookingAndAmountsSummaryByMonth)
            setBookingsAndAmountsByMonth(kpiBookingAndAmountsSummaryByMonth);
            setSummary(kpiSummary);
        }

        load();
    }, []);

    const customTickFormatter = (amount) => {
        return `${Math.ceil(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    }

    const sekFormatter = new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK'
    });

    useEffect(() => {

        if (filteredBookingsAndAmounts && bookingsAndTurnOverStartDate && bookingsAndTurnOverEndDate) {
            const filteredItems = bookingsAndAmounts.filter((bookingAndAmount) => {
                if (bookingAndAmount.date >= bookingsAndTurnOverStartDate && bookingAndAmount.date <= bookingsAndTurnOverEndDate) {
                    return bookingAndAmount;
                }
            });
            console.log(filteredItems);
            setFilteredBookingsAndAmounts(filteredItems);
        }
    }, [bookingsAndTurnOverStartDate, bookingsAndTurnOverEndDate])

    if (!bookingsAndAmounts || !summary || !bookingsAndAmountsByMonth) {
        return (
            <div className="mt-6 w-full">
                <LoadingSkeleton />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-y-6 mt-6 container items-center justify-center">
            <div className="flex md:flex-row flex-col justify-center gap-6 w-full">
                <div className="flex flex-col md:w-1/2 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Turnover this month</h2>
                    <span className="font-medium text-xl">{sekFormatter.format(summary.turnOverThisMonth)}</span>
                </div>
                <div className="flex flex-col md:w-1/2 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Turnover previous month</h2>
                    <span className="font-medium text-xl">{sekFormatter.format(summary.turnOverLastMonth)}</span>
                </div>
            </div>
            <div className="flex md:flex-row flex-col justify-center gap-6 w-full">
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Parking spots</h2>
                    <span className="font-medium text-xl">{summary.numberOfParkingSpots}</span>
                </div>
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Parking areas</h2>
                    <span className="font-medium text-xl">{summary.numberOfParkingAreas}</span>
                </div>
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Permits</h2>
                    <span className="text-xl font-medium">{summary.numberOfPermits}</span>
                </div>
                <div className="flex flex-col md:w-1/4 rounded-xl shadow-lg p-6 gap-y-3">
                    <h2>Users</h2>
                    <span className="font-medium text-xl">{summary.numberOfUsers}</span>
                </div>
            </div>
            <div className="flex md:flex-row flex-col w-full gap-x-6">
                <div className="flex gap-y-6 flex-col min-h-[420px] md:w-1/2 mt-6 shadow-xl roundex-xl p-6">
                    <h2 className="font-medium">Turnover & bookings per day</h2>
                    {filteredBookingsAndAmounts && bookingsAndTurnOverStartDate && bookingsAndTurnOverEndDate &&
                        <>
                            <div className="flex flex-row gap-x-6">
                                <input
                                    value={bookingsAndTurnOverStartDate}
                                    type="date"
                                    className="h-11 p-6 w-1/2 bg-gray-100 rounded-full border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    onInput={(e) => { setBookingsAndTurnOverStartDate(e.target.value) }}
                                />
                                <input
                                    value={bookingsAndTurnOverEndDate}
                                    type="date" className="h-11 p-6 bg-gray-100 w-1/2 rounded-full border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    onInput={(e) => { setBookingsAndTurnOverEndDate(e.target.value) }}
                                    
                                />
                            </div>
                            <ResponsiveContainer>
                                <ComposedChart
                                    width={300} height={200}
                                    data={filteredBookingsAndAmounts}
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <XAxis dataKey="friendlyDate" /><YAxis tickFormatter={customTickFormatter} unit='kr' width={80} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar name="Bookings" dataKey="bookingCount" barSize={20} fill="#33D085" />
                                    <Bar name="Total amount" dataKey="totalAmount" barSize={20} fill="#2886FF" />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </>
                    }

                </div>
                <div className="flex gap-y-6 flex-col min-h-[420px] md:w-1/2 mt-6 shadow-xl roundex-xl p-6">
                    <h2 className="font-medium">Turnover by month</h2>
                    <ResponsiveContainer>
                        <ComposedChart
                            width={300} height={200}
                            data={bookingsAndAmountsByMonth}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <XAxis dataKey="friendlyMonth" /><YAxis tickFormatter={customTickFormatter} unit='kr' width={80} />
                            <Tooltip />
                            <Legend />
                            <Bar name="Bookings" dataKey="bookingCount" barSize={20} fill="#33D085" />
                            <Bar name="Total amount" dataKey="totalAmount" barSize={20} fill="#2886FF" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default StartPage;
